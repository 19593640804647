<template>
  <v-layout v-if="value" class="mx-4" wrap>
    <v-flex xs12 md12>
      <v-row class="mx-0">
        <v-col cols="12" offset-1>
          <v-btn text color="rgba(0, 0, 0, 0.6)" class="px-0" @click="_back">
            <v-icon left> mdi-less-than </v-icon>
            ย้อนกลับ
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-0 mb-2">
        <v-col cols="6" class="promoter-h1">
          {{ form.id ? 'แก้ไข' : 'สร้าง' }}ของรางวัลพิเศษ
        </v-col>
      </v-row>
      <!-- Form -->
      <v-form ref="form" v-model="valid" @submit.prevent="_save">
        <v-row no-gutters>
          <v-col cols="6">
            <v-text-field
              v-model="form.name"
              label="ชื่อของรางวัลพิเศษ*"
              :rules="[...rulesRequired]"
              required
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3">
            <VueCtkDateTimePicker
              class="standard-component"
              v-model="form.startTime"
              format="YYYY-MM-DD HH:mm"
              formatted="DD-MM-YYYY HH:mm"
              color="#43a047"
              button-color="#43a047"
              noClearButton
              noLabel
              label="วันที่เริ่มต้น"
              @input="updateInput"
            />
          </v-col>
          <v-col cols="3">
            <VueCtkDateTimePicker
              class="standard-component"
              v-model="form.endTime"
              format="YYYY-MM-DD HH:mm"
              formatted="DD-MM-YYYY HH:mm"
              color="#43a047"
              button-color="#43a047"
              noClearButton
              noLabel
              label="วันที่สิ้นสุด"
              :minDate="minDate"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-textarea
              outlined
              label="เงื่อนไข"
              v-model="form.condition"
              :rules="[...rulesRequired]"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-autocomplete
              v-model="form.publish"
              :items="publish"
              :rules="[required]"
              label="Publish"
              outlined
              dense
              item-text="name"
              item-value="value"
              return-object
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-autocomplete
              v-model="form.reward"
              :items="reward"
              :rules="[required]"
              label="Reward"
              outlined
              dense
              item-text="title"
              item-value="id"
              return-object
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <v-switch v-model="form.isLimit" label="จำนวนจำกัด"></v-switch>
            <v-text-field
              v-model="form.limit"
              label="จำนวนจำกัด"
              :rules="form.limit !== 0 ? rules.limit : []"
              :disabled="!form.isLimit"
              required
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="6">
            <!-- <v-switch v-model="form.isPoint" label="คะแนนที่ใช้"></v-switch>
            <v-text-field
              v-model="form.point"
              label="คะแนนที่ใช้"
              :rules="form.point !== 0 ? rules.point : []"
              :disabled="!form.isPoint"
              required
              outlined
              dense
            /> -->
            <v-text-field
              v-model="form.point"
              label="คะแนนที่ใช้"
              :rules="form.point !== 0 ? rules.point : []"
              required
              outlined
              dense
            />
          </v-col>
        </v-row>

        <div class="text-right">
          <v-btn v-if="data.id" color="success" @click="_history"
            >ดูประวัติของรางวัลพิเศษ</v-btn
          >
          <v-btn class="ml-3" color="error" @click="_back">ยกเลิก</v-btn>
          <v-btn
            color="success"
            type="submit"
            class="ml-3 mr-2"
            :disabled="validate"
            >บันทึก</v-btn
          >
        </div>
      </v-form>
    </v-flex>
    <CustomModal
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :modal="modal"
      disableCancel
      disableBottomAction
      confirmIcon
      @confirm="toggleModal"
    >
      <template v-slot:actionHeader>
        <h3 class="black-text">ประวัติของรางวัลพิเศษ</h3>
      </template>
      <template v-slot:body>
        <v-data-table
          class="clean-tb"
          item-key="index"
          :headers="headers"
          :items="indexedItems"
          :footer-props="{ 'items-per-page-options': $itemsPerPage }"
          :items-per-page="limit"
          :loading="loading"
          :options.sync="options"
          :server-items-length="countItems"
          no-data-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
          no-results-text="ไม่พบข้อมูลหรือไม่มีสิทธิเข้าถึงข้อมูล"
        >
          <template v-slot:top>
            <v-btn class="d-flex ml-auto mb-2" color="success" @click="_export"
              >Export</v-btn
            >
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ moment(item.createdAt).format('DD/MM/YYYY HH:mm') }}
          </template>
        </v-data-table>
      </template>
    </CustomModal>
  </v-layout>
</template>

<script>
import moment from 'moment'
import { capitalize, get } from 'lodash'
import fileDownload from 'js-file-download'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

import CustomModal from '@/components/Modal'

import StampRewardModel from '@/store/models/changtt/stampReward'
import SpecialRewardModel from '@/store/models/changtt/specialReward'

export default {
  name: 'FormSpecialReward',
  components: {
    CustomModal,
    VueCtkDateTimePicker,
  },
  props: ['value', 'data'],
  created() {
    this.fetchData()
  },
  computed: {
    indexedItems() {
      return (
        this.items.map((item, index) => ({
          index: index + 1,
          ...item,
          address: `${item.shop.address} ${item.shop.subDistrict.name} ${item.shop.district.name} ${item.shop.province.name} ${item.shop.postcode}`,
        })) || []
      )
    },
    minDate() {
      const { startTime } = this.form
      const min = startTime ? moment(startTime).format('YYYY-MM-DD') : ''
      return min
    },
    validate() {
      // const { valid, form } = this;
      // return !valid || form.startTime === undefined || form.endTime === undefined;
      const { form } = this
      if (
        get(form, 'name', '') !== '' &&
        get(form, 'reward', '') !== '' &&
        get(form, 'publish.value', '') !== '' &&
        get(form, 'condition', '') !== '' &&
        form.startTime !== undefined &&
        form.endTime !== undefined &&
        // form.isPoint === true &&
        get(form, 'point', '') !== '' &&
        form.point > 0
      ) {
        if (form.isLimit === true) {
          if (get(form, 'limit', '') !== '' && form.limit > 0) return false
          else return true
        } else {
          return false
        }
      }
      return true
    },
  },
  data() {
    return {
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      limit: 10,
      countItems: 0,
      headers: [
        {
          text: 'No',
          value: 'index',
          sortable: false,
        },
        {
          text: 'วันที่แลกของ',
          value: 'createdAt',
          sortable: false,
        },
        {
          text: 'รหัสร้านค้า',
          value: 'shop.code',
          sortable: false,
        },
        { text: 'ชื่อร้านค้า', value: 'shop.name', sortable: false },
        { text: 'ที่อยู่', value: 'address', sortable: false },
      ],
      items: [],
      modal: false,
      valid: false,
      rulesRequired: [(v) => (v && v.length > 0) || 'กรุณากรอกข้อมูล'],
      rules: {
        point: [(v) => v > 0 || 'กรุณาระบุคะแนนที่ใช้มากกว่า 0'],
        limit: [(v) => v > 0 || 'กรุณาระบุจำนวนจำกัดมากกว่า 0'],
      },
      form: {
        startTime: undefined,
        endTime: undefined,
        isLimit: false,
        // isPoint: false,
      },
      publish: [
        { name: 'Public', value: true },
        { name: 'Hidden', value: false },
      ],
      reward: [],
    }
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.form = {
            ...this.data,
            // isPoint: this.data.point > 0 ? true : false,
            publish: { value: this.data.publish },
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    capitalize,
    moment,
    async fetchData() {
      this.loading = true
      const res = await StampRewardModel.list({})
      const { data } = res
      this.reward = (data && data.data) || []
      this.loading = false
    },
    updateInput() {
      if (this.form.endTime !== undefined) this.form.endTime = undefined
    },
    required(value) {
      if (value instanceof Array && value.length == 0) {
        return 'กรุณากรอกข้อมูล.'
      }
      return !!value || 'กรุณากรอกข้อมูล.'
    },
    toggleModal() {
      this.modal = !this.modal
    },
    async _history() {
      this.toggleModal()
      this.loading = true
      let options = {
        params: {
          premiumProductId: this.data.id,
          createdAt: 'desc',
        },
      }

      if (this.search) {
        options.params.query = this.search
      }

      if (this.options && this.options.itemsPerPage) {
        options.params.limit = this.options.itemsPerPage
      } else {
        options.params.limit = 10
      }

      if (this.options && this.options.page) {
        options.params.page = this.options.page
      }

      const res = await SpecialRewardModel.redeemList(options)
      const { data } = res
      this.items = (data && data.data) || []
      this.countItems = (data && data.total) || 0
      this.loading = false
    },
    async _export() {
      // TODO Export
      this.loading = true
      let options = {
        params: {
          premiumProductId: this.data.id,
          createdAt: 'desc',
        },
      }

      if (this.search) {
        options.params.query = this.search
      }

      if (this.status) {
        options.params.status = this.status
      }

      let filename = 'ProductRedeemExport.xlsx'
      const res = await SpecialRewardModel.download(options)
      filename = `ProductRedeemExport_${moment().format('YYMMDD_HHmmss')}.xlsx`
      fileDownload(res.data, filename)
      this.loading = false
    },
    _back() {
      this.$emit('input', false)
    },
    _fetch() {
      this.$emit('fetch')
    },
    _save() {
      // const valid = this.$refs.form.validate();
      // if (valid) {
      const form = this.form
      let formData = new FormData()

      formData.append('name', form.name)
      formData.append(
        'startTime',
        moment(form.startTime).format('YYYY-MM-DD HH:mm:ss')
      )
      formData.append(
        'endTime',
        moment(form.endTime).format('YYYY-MM-DD HH:mm:ss')
      )
      formData.append('condition', form.condition)
      formData.append('publish', form.publish.value)
      formData.append('rewardId', form.reward.id)
      if (form.isLimit !== undefined) formData.append('isLimit', form.isLimit)
      if (form.limit !== undefined) formData.append('limit', form.limit)
      formData.append('point', form.point)

      this.$emit('submit', formData, form.id)
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
$black: black;
$green: green;
$yellow: orange;
$brown: brown;

.black-text {
  color: $black;
}
.green-text {
  color: $green;
}
.yellow-text {
  color: $yellow;
}
.brown-text {
  color: $brown;
}

.col {
  padding: 10px 5px 10px 5px !important;
}

.promoter-h1 {
  font-size: 24px;
}
</style>
